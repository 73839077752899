import Layout from "@/layout";
import store from '@/store';
const { sell } = store.state.app.identity
export default
    // 发货管理
    {
        path: "/delivery",
        component: Layout,
        name: "Delivery",
        meta: { title: "发货管理" },
        children: [
            {
                // 订单发货
                path: "orderSend",
                name: "DeliveryOrder",
                parentName: "Delivery",
                fullPath: "/delivery/orderSend",
                component: () => import("@/views/Logistics/Delivery/order.vue"),
                meta: { title: "订单发货" },
            },
            {
                // 发货详情
                path: "deliveryOrderDetail",
                name: "DeliveryOrderDetail",
                parentName: "Delivery",
                isHide: true,
                fullPath: "/delivery/deliveryOrderDetail",
                component: () => import("@/views/Logistics/Delivery/orderDetail.vue"),
                meta: { title: "发货预览", activeMenu: "/logistics/delivery/order" },
            },
            {
                // 发货单
                path: "deliveryList",
                name: "DeliveryList",
                parentName: "Delivery",
                fullPath: "/delivery/deliveryList",
                component: () => import("@/views/Logistics/Delivery/deliveryList.vue"),
                meta: { title: "发货单", Identity: sell },
            },
            {
                // 发货单详情
                path: "deliveryDetail",
                name: "DeliveryDetail",
                parentName: "Delivery",
                isHide: true,
                fullPath: "/delivery/deliveryDetail",
                component: () => import("@/views/Logistics/Delivery/deliveryDetail.vue"),
                meta: {
                    title: "发货单详情",
                    activeMenu: "/delivery/deliveryList",
                },
            },
            {
                // 物流跟踪
                path: "logistics",
                name: "DeliveryLogistics",
                parentName: "Delivery",
                isHide: true,
                fullPath: "/delivery/logistics",
                component: () => import("@/views/Logistics/Delivery/logistics.vue"),
                meta: {
                    title: "物流信息维护",
                    activeMenu: "/delivery/deliveryList",
                },
            },
            // 订单备货
            {
                path: "order",
                name: "StockOrder",
                parentName: "Delivery",
                fullPath: "/delivery/order",
                component: () => import("@/views/Logistics/Stock/order.vue"),
                meta: { title: "订单备货" },
            },
            // 订单备货详情
            {
                path: "orderReadyDetail",
                name: "StockOrderDetail",
                parentName: "Delivery",
                isHide: true,
                fullPath: "/delivery/orderReadyDetail",
                component: () => import("@/views/Logistics/Stock/orderDetail.vue"),
                meta: { title: "备货预览", activeMenu: "/delivery/order" },
            },
            // 提货通知
            {
                path: "notice",
                name: "StockNotice",
                parentName: "Delivery",
                fullPath: "/delivery/notice",
                component: () => import("@/views/Logistics/Stock/notice.vue"),
                meta: { title: "提货通知", Identity: sell },
            },
            // 提货通知详情
            {
                path: "noticeDetail",
                name: "StockNoticeDetail",
                parentName: "Delivery",
                isHide: true,
                fullPath: "/delivery/noticeDetail",
                component: () => import("@/views/Logistics/Stock/noticeDetail.vue"),
                meta: { title: "提货通知详情", activeMenu: "/delivery/notice" },
            },
            // 提货单列表
            {
                path: "bill",
                name: "StockBill",
                parentName: "Delivery",
                fullPath: "/delivery/bill",
                component: () => import("@/views/Logistics/Stock/bill.vue"),
                meta: { title: "提货单", Identity: sell },
            },
            // 提货单详情
            {
                path: "billDetail",
                name: "StockBillDetail",
                parentName: "Delivery",
                isHide: true,
                fullPath: "/delivery/billDetail",
                component: () => import("@/views/Logistics/Stock/billDetail.vue"),
                meta: { title: "提货单详情", activeMenu: "/delivery/bill" },
            },
            // 确认提货
            {
                path: "billConfirm",
                name: "StockBillConfirm",
                parentName: "Delivery",
                isHide: true,
                fullPath: "/delivery/billConfirm",
                component: () => import("@/views/Logistics/Stock/billConfirm.vue"),
                meta: { title: "确认提货", activeMenu: "/delivery/bill" },
            }
        ],
    }

