import Layout from "@/layout";
import store from '@/store';
const { purchase } = store.state.app.identity
export default
    // 收货管理
    {
        path: "/collect",
        component: Layout,
        name: "Collect",
        meta: { title: "收货管理" },
        children: [
            {
                // 订单收货
                path: "orderCollect",
                name: "CollectOrder",
                parentName: "Collect",
                fullPath: "/collect/orderCollect",
                component: () => import("@/views/Logistics/Collect/order.vue"),
                meta: { title: "订单收货", Identity: purchase },
            },
            {
                // 收货详情
                path: "orderCollectDetail",
                name: "CollectOrderDetail",
                parentName: "Collect",
                isHide: true,
                fullPath: "/collect/orderCollectDetail",
                component: () => import("@/views/Logistics/Collect/orderDetail.vue"),
                meta: { title: "收货详情", activeMenu: "/collect/orderCollect" },
            },
            {
                // 物流跟踪
                path: "logistics",
                name: "CollectLogistics",
                parentName: "Collect",
                isHide: true,
                fullPath: "/collect/logistics",
                component: () => import("@/views/Logistics/Collect/logistics.vue"),
                meta: { title: "物流信息", activeMenu: "/collect/orderCollect" },
            },
            {
                // 收货单
                path: "collectList",
                name: "CollectList",
                parentName: "Collect",
                fullPath: "/collect/collectList",
                component: () => import("@/views/Logistics/Collect/collectList.vue"),
                meta: { title: "收货单" },
            },
            {
                // 收货单详情
                path: "collectDetail",
                name: "CollectDetail",
                parentName: "Collect",
                isHide: true,
                fullPath: "/collect/collectDetail",
                component: () => import("@/views/Logistics/Collect/collectDetail.vue"),
                meta: { title: "收货单详情", activeMenu: "/collect/collectList" },
            },
            {
                path: "order",
                name: "PickOrder",
                parentName: "Collect",
                fullPath: "/collect/order",
                component: () => import("@/views/Logistics/Pick/order.vue"),
                meta: { title: "订单提货", Identity: purchase },
            },
            // 生成提货单
            {
                path: "createBill",
                name: "PickCreateBill",
                parentName: "Collect",
                isHide: true,
                fullPath: "/collect/createBill",
                component: () => import("@/views/Logistics/Pick/createBill.vue"),
                meta: { title: "生成提货单", activeMenu: "/collect/order" },
            },
            // 提货单
            {
                path: "bill",
                name: "PickBill",
                parentName: "Collect",
                fullPath: "/collect/bill",
                component: () => import("@/views/Logistics/Pick/bill.vue"),
                meta: { title: "提货单", Identity: purchase },
            },
            // 提货单详情
            {
                path: "billDetail",
                name: "PickBillDetail",
                parentName: "Collect",
                isHide: true,
                fullPath: "/collect/billDetail",
                component: () => import("@/views/Logistics/Pick/billDetail.vue"),
                meta: { title: "提货单详情", activeMenu: "/collect/bill" },
            }
        ]
    }