import { getMessageList, getMessageConfigList } from '@/api/message'
const state = {
  list: [],
  total: 0,
  msgType: []
}
const mutations = {
  SET_LIST(state, list) {
    state.list = list
  },
  MESSAGETOTAL(state, total){
    state.total = total
  },
  SETMSGTYPE(state, list){
    state.msgType = list
  }
}
const actions = {
  async getMessageList({commit}, params){
    let result = await getMessageList(params)
    if (result.code == 0){
      commit('SET_LIST', result.data.records)
      commit('MESSAGETOTAL', result.data.total)
    }
  },
  async getMessageConfigList({commit}){
    let result = await getMessageConfigList()
    if (result.code == 0) {
      commit('SETMSGTYPE', result.data)
    }
  }
}
const getters = {
  msgTypeList(state) {
    return state.msgType.map(item => {
      return {
        label: item.msgType,
        value: String(item.id)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}