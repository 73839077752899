import router from './router'
import store from './store'
import { asyncRoutes } from '@/router/index'
import { getToken, getUserInfo, setToken } from "@/store/mutation-types"

const whiteList = ['/login'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    setToken(to.query.token)
    let query = to.query
    delete query.token
    return next({ path: to.path, query})
  }
  const hasToken = store.getters['user/token']
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      const userInfo = getUserInfo();
      if (userInfo && userInfo.mobile) {
        if (to.matched.length === 0){
          let data = handleTree(asyncRoutes)
          if(data.findIndex(it => it.name === to.name) !== -1){
            next({ path: '/403' })
          }else{
            next({ path: '/404' })
          }
        }else next() 
      } else {
        try {
          const result = await store.dispatch("user/getInfo");
          if (result.main) {
            store.commit('permission/SET_ROUTES', asyncRoutes);
            asyncRoutes.forEach(it => {
              router.addRoute(it)
            })
            next({ ...to, replace: true })
          } else {
            const accessRoutes = await store.dispatch('permission/generateRoutes');
            if (accessRoutes.length) {
              accessRoutes.forEach(it => {
                router.addRoute(it)
              })
              next({ ...to, replace: true })
            } else {
              next()
            }
          }
        } catch (error) {
          await store.dispatch("user/resetToken");
          next(`/login?redirect=${to.path}`);
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }

  }
})
function handleTree(data) {
  let result = [];
  data.forEach(el => {
    result.push({ ...el, children: [] })
    if (el.children && el.children.length) {
      result = result.concat(handleTree(el.children))
    }
  })
  return result
}